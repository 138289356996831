<template>
  <div class="myproject">
    <div class="mypeoject-content">
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :md="6"
          :sm="8"
          :lg="6"
          :xl="4"
          v-for="(item,index) in projectList"
          :key="index"
          class="el-item"
        >
          <div class="grid-content bg-purple" @click="goPage(item.url)">
            <div class="item-banner">
              <img :src="item.img" alt="">
            </div>
            <div class="item-info">
              <div class="item-name">{{item.title}}</div>
              <div class="item-introduce" style="-webkit-box-orient: vertical">{{item.introduce}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "project",
    data () {
      return {
        projectList : []
      }
    },
    created () {
      this.getProjectList()
    },
    methods : {
      async getProjectList () {
        let res = await this.$http({
          url : '/api/project/project_list',
          method : 'GET',
        })
        if (res.code === 0) {
          this.projectList = res.data
          this.$store.commit('addCachePage','project')
        }
      },
      goPage (p) {
        window.open(p)
      }
    }
  }
</script>

<style scoped lang="scss">
  .myproject {
    padding: 20px 40px 0;
  }
  .el-item {
    margin-bottom: 20px;
    min-height: 260px;
  }
  .item-info {
    margin-top: 10px;
    .item-name {
      font-size: 16px;
      font-weight: 400;
    }
    .item-introduce {
      font-size: 14px;
      margin-top: 5px;
      color: rgba(0,0,0,.57);
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
    }
  }
  .item-banner {
    width: 100%;
    height: 160px;
    img {
      width: 100%;
      object-fit: cover;
      max-width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  @media (max-width: 768px) {
    .el-item {
      min-height: 0;
    }
  }
</style>
